<template>
  <div>
    <div class="text-center mb-6">¡Tu pago se ha realizado con éxito!</div>
    <v-row justify="center">
      <v-img :src="require(`@/assets/img/Success-payment.svg`)" contain max-height="100" max-width="150" />
    </v-row>
    <div class="text-center roboto text-subtitle-1 mt-9">
      <div>
        Con fecha {{ paymentDate | formatDate }} se ha registrado el pago por
        <strong> {{ prevFormData.amount | numeral("0,0") }} USD. </strong>
      </div>
      <div>
        Hemos enviado un comprobante al correo
        <strong> {{ prevFormData.payerEmail }}. </strong>
      </div>
      <div>
        También puedes descargar tu comprobante de pago o verlos en la sección
        <strong> Mis Pagos.</strong>
      </div>
    </div>
    <v-row justify="center" class="mt-6" v-if="paymentData && prevFormData">
      <v-card class="pa-3 px-5" width="400">
        <v-row justify="start">
          <div class="text-h6 mt-2 mx-2">Detalles del pago:</div>
        </v-row>
        <v-row justify="start">
          <v-col>
            <div class="roboto text-subtitle-1">Código de la transacción:</div>
            <div class="text-profile roboto">
              {{ paymentData.transactionId ? paymentData.transactionId : paymentData.id }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="roboto text-subtitle-1">Monto pagado:</div>
            <div class="text-profile roboto">$ {{ prevFormData.amount | numeral("0,0") }} USD</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="roboto text-subtitle-1">Fecha de la transacción:</div>
            <div class="text-profile roboto">
              {{ paymentDate | formatDate }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="roboto text-subtitle-1">Hora de la transacción:</div>
            <div class="text-profile roboto">
              {{ paymentDate | formatDateHour }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="roboto text-subtitle-1">Tarjeta:</div>
            <div class="text-profile roboto">
              {{ prevFormData.cardNumber | protectedPaymentCard }}
            </div>
          </v-col>
        </v-row>
        <v-row v-if="paymentData.authorizationCode">
          <v-col>
            <div class="roboto text-subtitle-1">Código de autorización:</div>
            <div class="text-profile roboto">
              {{ paymentData.authorizationCode ? paymentData.authorizationCode : "" | protectedPaymentCard }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="roboto text-subtitle-1">Tipo de pago:</div>
            <div class="text-profile roboto">
              {{ paymentType }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="roboto text-subtitle-1">Número de cuotas:</div>
            <div class="text-profile roboto">
              {{ prevFormData.installments }}
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-row justify="center mt-12 mb-7">
      <v-btn class="tway-violet--text mr-4 roboto" large color="white" text :to="{ name: 'home' }">
        VOLVER AL INICIO
      </v-btn>
      <v-btn large rounded color="tway-apple-green" class="white--text roboto" :to="{ name: 'my-payment' }">
        VER MIS PAGOS
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import subscriptionService from "@/services/subscriptionService";
import moment from "moment";

const PAYMENT_TYPES = {
  DEBIT: "Débito",
  CREDIT: "Crédito",
};

export default {
  data() {
    return {
      paymentData: {},
      prevFormData: {},
      paymentDate: "",
    };
  },
  mounted() {
    this.paymentData = this.$route.params.paymentData;
    this.prevFormData = this.$route.params.prevFormData;
    this.paymentDate = moment().toISOString(this.paymentData.operationDate);
    this.addPayment();
  },
  methods: {
    addPayment() {
      subscriptionService
        .getCurrentUserSubscription()
        .then((subscription) => {
          let paymentDTO = {
            paymentDate: this.paymentDate,
            paymentMethod: this.prevFormData.paymentMethod,
            planId: this.prevFormData.planId,
            buyOrder: this.paymentData.orderId ? this.paymentData.orderId : this.paymentData.id,
            amount: this.prevFormData.amount,
            paymentMethodData: JSON.stringify({
              ...this.paymentData,
              ...this.prevFormData,
            }),
          };
          subscriptionService
            .addPayment(subscription.id, paymentDTO)
            .catch((err) => this.$log.error("Error trying to add a payment to subscription - ", err))
            .then(() => (this.isLoading = false));
        })
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .then(() => (this.isLoading = false));
    },
  },
  computed: {
    paymentType() {
      return this.paymentData && this.paymentData.additionalInfo
        ? PAYMENT_TYPES[this.paymentData.additionalInfo.cardType]
        : this.prevFormData.issuer;
    },
  },
};
</script>

<style lang="css" scoped>
.text-profile {
  color: #666666;
}
</style>
>
